import {
    Box,
    Button,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    Select,
    SelectChangeEvent,
    Tab,
    Tabs,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material';
import {
    addCampaign,
    addPerformanceEstimate,
    removeBuyer,
    removeCampaign,
    removePerformanceEstimateByCampaignAndNetworkName,
    selectBuyersAvailableNetworks,
    setBuyerCurrentNetworks,
    updateBuyerName,
    updateCampaignDetails,
} from '../../features/ssp/slice-extensions/ssp-slice';
import { Campaign } from '../../features/ssp/Campaign';
import { BuyerCampaign } from '../../features/ssp/BuyerCampaign';
import CampaignPerformanceEstimates from '../campaign-performance-estimates/CampaignPerformanceEstimates';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import NumericThousandSeparatedTextField from '../numeric-thousand-separated-text-field/NumericThousandSeparatedTextField';
import { ObjectState } from '../../features/ObjectState';
import { AvailableNetwork } from '../../features/ssp/AvailableNetwork';
import { StringsExtension } from '../../features/ssp/extensions/StringsExtension';
import { PerformanceEstimate } from '../../features/ssp/PerformanceEstimate';
import { NetworkColorHelper } from '../../features/ssp/NetworkColorHelper';
import { useState } from 'react';
import { AddOutlined, MoreHoriz } from '@mui/icons-material';
import RequireRoles from '../require-roles/RequireRoles';
import { UserRoles } from '../../features/authentication/UserRoles';
import { useParams } from 'react-router-dom';

export interface BuyerCampaignFormProps {
    buyerCampaign: BuyerCampaign;
    maxDateModified: Date;
    minDate: Date;
    isSaveBarVisible: boolean;
}

export default function BuyerCampaignForm({
    buyerCampaign,
    maxDateModified,
    minDate,
    isSaveBarVisible,
}: BuyerCampaignFormProps) {
    const inputWidth = 182;
    const dispatch = useAppDispatch();
    const buyersAvailableNetworks: AvailableNetwork[] = useAppSelector(
        selectBuyersAvailableNetworks
    );
    const stringsExtension = new StringsExtension();
    const campaigns = buyerCampaign.campaigns.filter(
        (campaign) => campaign.objectState !== ObjectState.Deleted
    );
    const colorHelper = NetworkColorHelper.getInstance();
    const [anchorElAgency, setAnchorElAgency] = useState<null | HTMLElement>(
        null
    );
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const isMobile = useMediaQuery('(max-width:920px)');
    const params = useParams();

    const handleChange = (_: any, newValue: number) => {
        setSelectedTab(newValue);
    };

    const getCompaigns = () => {
        const names = campaigns.map((x) => x.name);
        return names;
    };

    const [anchorElCampaign, setAnchorElCampaign] =
        useState<null | HTMLElement>(null);

    const handleCampaignMenuOpen = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorElCampaign(event.currentTarget);
    };

    const handleCampaignMenuClose = () => {
        setAnchorElCampaign(null);
    };
    const handleAgencyMenuOpen = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorElAgency(event.currentTarget);
    };

    const handleAgencyMenuClose = () => {
        setAnchorElAgency(null);
    };

    const createNewCampaign = (campaignIndex: number) => {
        const newCampaign: Campaign = {
            id: new Date().getTime(),
            brandName: `Brand ${campaignIndex}`,
            productName: `Product ${campaignIndex}`,
            name: `Campaign ${campaignIndex}`,
            budget: undefined,
            minMinutesBetweenAds: 1,
            performanceEstimates: [],
            objectState: ObjectState.Added,
        };
        setSelectedTab(campaignIndex - 1);
        dispatch(
            addCampaign({
                buyerId: buyerCampaign.buyer.id,
                newCampaign: newCampaign,
            })
        );
    };

    const handleBuyerNameChange = (newBuyerName: string) => {
        dispatch(
            updateBuyerName({
                buyerId: buyerCampaign.buyer.id,
                newBuyerName: newBuyerName,
            })
        );
    };

    const handleCampaignDetailChange = (
        campaignId: number,
        name: string,
        brandName: string,
        productName: string,
        minMinutesBetweenAds: number,
        budget: number | undefined
    ) => {
        dispatch(
            updateCampaignDetails({
                campaignId: campaignId,
                name: name,
                brandName: brandName,
                productName: productName,
                budget: budget,
                minMinutesBetweenAds: minMinutesBetweenAds,
            })
        );
    };

    const handleCurrentAvailableNetworkChange = (
        campaignId: number,
        currentNetworks: AvailableNetwork[],
        event: SelectChangeEvent<string[]>,
        performanceEstimates: PerformanceEstimate[]
    ) => {
        const {
            target: { value },
        } = event;
        const selectedValues: string[] =
            typeof value === 'string' ? value.split(',') : value;

        const availableNetworks: AvailableNetwork[] = selectedValues
            .map((selectedValue) =>
                buyersAvailableNetworks.find((network) =>
                    stringsExtension.stringsEqual(
                        network.networkName,
                        selectedValue
                    )
                )
            )
            .filter(
                (network): network is AvailableNetwork => network !== undefined
            );

        dispatch(
            setBuyerCurrentNetworks({
                campaignId: campaignId,
                availableNetworks: availableNetworks,
            })
        );

        const availableNetworksDifference = currentNetworks.filter(
            (currentNetwork) =>
                availableNetworks.findIndex(
                    (changedNetwork) => currentNetwork.id === changedNetwork.id
                ) === -1
        );
        if (
            typeof availableNetworksDifference !== 'undefined' &&
            availableNetworksDifference.length > 0
        ) {
            availableNetworksDifference.forEach((availableNetwork) => {
                dispatch(
                    removePerformanceEstimateByCampaignAndNetworkName({
                        campaignId: campaignId,
                        networkName: availableNetwork.networkName,
                    })
                );
            });
        }

        createPerformanceEstimateIfNotExists(
            campaignId,
            performanceEstimates,
            availableNetworks
        );
    };

    const createPerformanceEstimateIfNotExists = (
        campaignId: number,
        performanceEstimates: PerformanceEstimate[],
        currentNetworks: AvailableNetwork[]
    ) => {
        if (currentNetworks.length > 0) {
            const existingNetworkNames = performanceEstimates
                .filter(
                    (est) =>
                        typeof est !== 'undefined' &&
                        typeof est.networkName !== 'undefined' &&
                        est.objectState !== ObjectState.Deleted
                )
                .map((est) => est.networkName);
            const networksToAdd = currentNetworks.filter((network) => {
                return !existingNetworkNames.includes(network.networkName);
            });

            networksToAdd.forEach((network) => {
                dispatch(
                    addPerformanceEstimate({
                        campaignId: campaignId,
                        networkName: network.networkName,
                    })
                );
            });
        }
    };

    return (
        <Box
            sx={{
                mb: 6,
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
                '& .MuiInputLabel-root': {
                    mb: 0.5,
                },
            }}>
            <Box>
                <InputLabel>Agency</InputLabel>

                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Box>
                        <TextField
                            sx={{
                                width: (theme) =>
                                    isMobile ? 'auto' : theme.spacing(34),
                            }}
                            variant="outlined"
                            value={buyerCampaign.buyer.name}
                            onChange={(event) =>
                                handleBuyerNameChange(event.target.value)
                            }
                        />
                    </Box>
                    {typeof params.id === 'undefined' && (
                        <RequireRoles roles={UserRoles.Admin}>
                            <>
                                <IconButton onClick={handleAgencyMenuOpen}>
                                    <MoreHoriz
                                        sx={{
                                            width: (theme) => theme.spacing(3),
                                            height: (theme) => theme.spacing(3),
                                            fontSize: (theme) =>
                                                theme.spacing(2),
                                            fontWeight: '400',
                                            lineHeight: (theme) =>
                                                theme.spacing(2),
                                            textAlign: 'center',
                                            color: (theme) =>
                                                theme.palette.primary.dark,
                                        }}
                                    />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorElAgency}
                                    open={Boolean(anchorElAgency)}
                                    sx={{ '& .MuiList-root': { padding: '0' } }}
                                    onClose={handleAgencyMenuClose}>
                                    <MenuItem
                                        sx={{
                                            width: (theme) =>
                                                theme.spacing(13.25),
                                            height: (theme) =>
                                                theme.spacing(5.5),
                                            padding: (theme) =>
                                                `${theme.spacing(2)}`,
                                            fontSize: (theme) =>
                                                theme.spacing(2),
                                            fontWeight: '500',
                                            lineHeight: (theme) =>
                                                theme.spacing(2),
                                            textAlign: 'left',
                                            color: '#C50003',
                                            background: 'white',
                                        }}
                                        onClick={() => {
                                            dispatch(
                                                removeBuyer({
                                                    buyerId:
                                                        buyerCampaign.buyer.id,
                                                })
                                            );
                                            handleAgencyMenuClose();
                                        }}>
                                        Delete
                                    </MenuItem>
                                </Menu>
                            </>
                        </RequireRoles>
                    )}
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& .MuiTabs-root': {
                        minHeight: (theme) => theme.spacing(3.75),
                        alignItems: 'flex-end',
                    },
                    '& .MuiButtonBase-root': {
                        textTransform: 'none',
                        color: (theme) => theme.palette.primary.dark,
                        minHeight: (theme) => theme.spacing(3.75),
                    },
                    '& .Mui-selected': {
                        color: (theme) =>
                            `${theme.palette.primary.main} !important`,
                    },
                }}>
                <Tabs
                    sx={{
                        '& .MuiTabs-flexContainer': {
                            overflow: 'auto',
                        },
                        '& .MuiTab-root ': {
                            height: (theme) => theme.spacing(3.75),
                            padding: (theme) =>
                                `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
                            fontSize: (theme) => theme.spacing(2),
                            whiteSpace: 'nowrap',
                        },
                        '& .MuiTab-root:not(.Mui-selected)': {
                            borderBottom: '1px solid #00000026',
                        },
                    }}
                    value={selectedTab}
                    onChange={handleChange}>
                    {getCompaigns().map((campaign) => (
                        <Tab label={campaign} key={campaign} />
                    ))}
                </Tabs>
                <Button
                    sx={{
                        padding: (theme) =>
                            `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
                        color: (theme) =>
                            `${theme.palette.primary.main} !important`,
                    }}
                    disabled={campaigns.length === 3}
                    onClick={() => createNewCampaign(campaigns.length + 1)}
                    startIcon={
                        <AddOutlined
                            sx={{ opacity: campaigns.length === 3 ? 0.75 : 1 }}
                        />
                    }>
                    <Box
                        sx={{
                            fontSize: (theme) => theme.spacing(2),
                            fontWeight: '600',
                            opacity: campaigns.length === 3 ? 0.75 : 1,
                        }}>
                        New campaign
                    </Box>
                </Button>
            </Box>
            {campaigns.map(
                (campaign, index) =>
                    selectedTab === index && (
                        <Box key={index}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    flexDirection: 'column',
                                }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 1,
                                        alignItems: 'center',
                                    }}>
                                    <Typography variant="h5" component="h3">
                                        {campaign.name}
                                    </Typography>

                                    <Box>
                                        <IconButton
                                            onClick={handleCampaignMenuOpen}>
                                            <MoreHoriz
                                                sx={{
                                                    width: (theme) =>
                                                        theme.spacing(3),
                                                    height: (theme) =>
                                                        theme.spacing(3),
                                                    fontSize: (theme) =>
                                                        theme.spacing(2),
                                                    fontWeight: '400',
                                                    lineHeight: (theme) =>
                                                        theme.spacing(2),
                                                    textAlign: 'center',
                                                    color: (theme) =>
                                                        theme.palette.primary
                                                            .dark,
                                                }}
                                            />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorElCampaign}
                                            open={Boolean(anchorElCampaign)}
                                            sx={{
                                                '& .MuiList-root': {
                                                    padding: '0',
                                                },
                                            }}
                                            onClose={handleCampaignMenuClose}>
                                            <MenuItem
                                                sx={{
                                                    width: (theme) =>
                                                        theme.spacing(13.25),
                                                    height: (theme) =>
                                                        theme.spacing(5.5),
                                                    padding: (theme) =>
                                                        `${theme.spacing(2)}`,
                                                    fontSize: (theme) =>
                                                        theme.spacing(2),
                                                    fontWeight: '500',
                                                    lineHeight: (theme) =>
                                                        theme.spacing(2),
                                                    textAlign: 'left',
                                                    color: '#C50003',
                                                    background: 'white',
                                                }}
                                                onClick={() => {
                                                    const campaignIndex =
                                                        campaigns.findIndex(
                                                            (c) =>
                                                                c.id ===
                                                                campaign.id
                                                        );

                                                    const newTab =
                                                        campaignIndex > 0
                                                            ? campaignIndex - 1
                                                            : 0;

                                                    setSelectedTab(newTab);
                                                    dispatch(
                                                        removeCampaign({
                                                            campaignId:
                                                                campaign.id,
                                                        })
                                                    );
                                                    handleCampaignMenuClose();
                                                }}>
                                                Delete
                                            </MenuItem>
                                        </Menu>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 5,
                                        flexDirection: isMobile
                                            ? 'column'
                                            : 'row',
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: (theme) => theme.spacing(2),
                                            padding: (theme) =>
                                                theme.spacing(2),
                                            borderRadius: (theme) =>
                                                theme.spacing(1),
                                            backgroundColor: (theme) =>
                                                theme.palette.secondary.dark,
                                            '& .MuiInputBase-formControl': {
                                                width: (theme) =>
                                                    isMobile
                                                        ? 'auto'
                                                        : theme.spacing(51),
                                                height: (theme) =>
                                                    theme.spacing(5),
                                            },
                                            width: 'fit-content',
                                        }}>
                                        <Box>
                                            <InputLabel>Brand</InputLabel>
                                            <TextField
                                                variant="outlined"
                                                value={campaign.brandName}
                                                onChange={(event) =>
                                                    handleCampaignDetailChange(
                                                        campaign.id,
                                                        campaign.name,
                                                        event.target.value,
                                                        campaign.productName,
                                                        campaign.minMinutesBetweenAds,
                                                        campaign.budget
                                                    )
                                                }
                                                inputProps={{ maxLength: 1000 }}
                                            />
                                        </Box>
                                        <Box>
                                            <InputLabel>
                                                Product (optional)
                                            </InputLabel>
                                            <TextField
                                                variant="outlined"
                                                value={campaign.productName}
                                                onChange={(event) =>
                                                    handleCampaignDetailChange(
                                                        campaign.id,
                                                        campaign.name,
                                                        campaign.brandName,
                                                        event.target.value,
                                                        campaign.minMinutesBetweenAds,
                                                        campaign.budget
                                                    )
                                                }
                                                inputProps={{ maxLength: 1000 }}
                                            />
                                        </Box>
                                        <Box>
                                            <InputLabel>Campaign</InputLabel>
                                            <TextField
                                                variant="outlined"
                                                value={campaign.name}
                                                onChange={(event) =>
                                                    handleCampaignDetailChange(
                                                        campaign.id,
                                                        event.target.value,
                                                        campaign.brandName,
                                                        campaign.productName,
                                                        campaign.minMinutesBetweenAds,
                                                        campaign.budget
                                                    )
                                                }
                                                inputProps={{ maxLength: 1000 }}
                                            />
                                        </Box>
                                        <Box>
                                            <InputLabel>Network</InputLabel>
                                            <FormControl
                                                variant="outlined"
                                                size="medium">
                                                <Select
                                                    name={`availableNetworks ${campaign.id}`}
                                                    multiple
                                                    displayEmpty
                                                    sx={{
                                                        mr: 1,
                                                        width: inputWidth,
                                                        minWidth: inputWidth,
                                                        maxWidth: isMobile
                                                            ? inputWidth
                                                            : 'none',
                                                    }}
                                                    value={
                                                        campaign.currentNetworks?.map(
                                                            (network) =>
                                                                network.networkName
                                                        ) || []
                                                    }
                                                    onChange={(event) => {
                                                        handleCurrentAvailableNetworkChange(
                                                            campaign.id,
                                                            campaign.currentNetworks ||
                                                                [],
                                                            event,
                                                            campaign.performanceEstimates
                                                        );
                                                    }}
                                                    disabled={
                                                        campaigns.length <= 0
                                                    }
                                                    renderValue={(selected) => (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                gap: 0.5,
                                                                overflow:
                                                                    'auto',
                                                            }}>
                                                            {selected.map(
                                                                (value) => (
                                                                    <Box
                                                                        key={
                                                                            value
                                                                        }
                                                                        sx={{
                                                                            pl: 1,
                                                                            pr: 1,
                                                                            borderRadius: 1,
                                                                            background:
                                                                                colorHelper.getColor(
                                                                                    value
                                                                                ),
                                                                            border: '1px solid #00000026',
                                                                            height: (
                                                                                theme
                                                                            ) =>
                                                                                theme.spacing(
                                                                                    3
                                                                                ),
                                                                        }}>
                                                                        {value}
                                                                    </Box>
                                                                )
                                                            )}
                                                        </Box>
                                                    )}>
                                                    {buyersAvailableNetworks.map(
                                                        (
                                                            availableNetwork,
                                                            i
                                                        ) => (
                                                            <MenuItem
                                                                key={i}
                                                                value={
                                                                    availableNetwork.networkName
                                                                }>
                                                                {
                                                                    availableNetwork.networkName
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>{' '}
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: (theme) => theme.spacing(2),
                                            padding: (theme) =>
                                                theme.spacing(2),
                                            borderRadius: (theme) =>
                                                theme.spacing(1),
                                            '& .MuiInputBase-formControl': {
                                                width: (theme) =>
                                                    isMobile
                                                        ? inputWidth
                                                        : theme.spacing(20),
                                                height: (theme) =>
                                                    theme.spacing(5),
                                            },
                                            width: 'fit-content',
                                        }}>
                                        <Box>
                                            <InputLabel>Unit Length</InputLabel>
                                            <FormControl
                                                variant="outlined"
                                                size="medium">
                                                <Select
                                                    name={`unit-length ${campaign.id}`}
                                                    sx={{
                                                        mr: 1,
                                                        width: inputWidth,
                                                    }}
                                                    value={120}
                                                    onChange={(_) => {}}>
                                                    <MenuItem
                                                        key={5}
                                                        value={5}
                                                        disabled>
                                                        5s
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={10}
                                                        value={10}
                                                        disabled>
                                                        10s
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={15}
                                                        value={15}
                                                        disabled>
                                                        15s
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={20}
                                                        value={20}
                                                        disabled>
                                                        20s
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={25}
                                                        value={25}
                                                        disabled>
                                                        25s
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={30}
                                                        value={30} disabled>
                                                        30s
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={40}
                                                        value={40}
                                                        disabled>
                                                        40s
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={50}
                                                        value={50}
                                                        disabled>
                                                        50s
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={60}
                                                        value={60}
                                                        disabled>
                                                        60s
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={120}
                                                        value={120}>
                                                        120s
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box>
                                            <NumericThousandSeparatedTextField
                                                value={
                                                    campaign.minMinutesBetweenAds
                                                }
                                                helperText="Minimum time between ads"
                                                onChange={(event) => {
                                                    const parsedValue =
                                                        parseFloat(
                                                            event.target.value.replace(
                                                                /,/g,
                                                                ''
                                                            )
                                                        );
                                                    handleCampaignDetailChange(
                                                        campaign.id,
                                                        campaign.name,
                                                        campaign.brandName,
                                                        campaign.productName,
                                                        parsedValue,
                                                        campaign.budget
                                                    );
                                                }}
                                                withSign={true}
                                                sign={
                                                    campaign.minMinutesBetweenAds >
                                                    1
                                                        ? 'mins'
                                                        : 'min'
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <InputLabel>Dayparts</InputLabel>

                                            <FormControl
                                                variant="outlined"
                                                size="medium">
                                                <Select
                                                    name={`dayparts ${campaign.id}`}
                                                    sx={{
                                                        mr: 1,
                                                        width: inputWidth,
                                                    }}
                                                    value={2}
                                                    onChange={(_) => {}}>
                                                    <MenuItem
                                                        key={1}
                                                        value={1}
                                                        disabled>
                                                        Prime
                                                    </MenuItem>
                                                    <MenuItem key={2} value={2}>
                                                        Daytime
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={3}
                                                        value={3}
                                                        disabled>
                                                        Early Fringe
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={4}
                                                        value={4}
                                                        disabled>
                                                        Late Night
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box>
                                            <NumericThousandSeparatedTextField
                                                value={
                                                    campaign.budget &&
                                                    !Number.isNaN(
                                                        campaign.budget
                                                    )
                                                        ? campaign.budget
                                                        : 0
                                                }
                                                helperText="Budget"
                                                onChange={(event) => {
                                                    const parsedValue =
                                                        parseFloat(
                                                            event.target.value.replace(
                                                                /,/g,
                                                                ''
                                                            )
                                                        );
                                                    handleCampaignDetailChange(
                                                        campaign.id,
                                                        campaign.name,
                                                        campaign.brandName,
                                                        campaign.productName,
                                                        campaign.minMinutesBetweenAds,
                                                        parsedValue
                                                    );
                                                }}
                                                withSign
                                                sign="$"
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        '& .MuiTextField-root': {
                                            mr: 1,
                                            width: inputWidth,
                                        },
                                    }}></Box>
                                <CampaignPerformanceEstimates
                                    isSaveBarVisible={isSaveBarVisible}
                                    maxDateModified={maxDateModified}
                                    minDate={minDate}
                                    campaignId={campaign.id}
                                    performanceEstimates={
                                        campaign.performanceEstimates
                                    }
                                    availableNetworks={
                                        campaign.currentNetworks || []
                                    }></CampaignPerformanceEstimates>
                            </Box>
                            <Divider></Divider>
                        </Box>
                    )
            )}
        </Box>
    );
}
